<template>
<div class="container-fluid px-0 py-2">
    <div class="col-12">
        <div class="row">
            <div class="col-12">
                <div class="row">
                    <div class="col-12">
                        <div class="p-dash-cliente-master">
                            <div class="col-12 col-md-11 col-lg-10 mx-auto pt-4">
                                <div class="row">
                                    <div class="col-12 col-md-6 col-lg-6 px-0 py-2">
                                        <!-- <img src="../../assets/images/hub_/hub-logo.svg" class="img-fluid" title="Logo" style="max-height:40px"> -->
                                    </div>
                                    <div class="col-12 col-md-6 col-lg-4 ml-auto px-0 py-2 text-right">
                                    </div>
                                    <div class="col-12 text-center">
                                        <h3 class="font-25 font-b-4 mb-1" style="color: var(--color);">Obrigado <span class="color-6 font-b-5">{{ user.first_name }}!</span></h3>
                                        <span class="font-18 font-b-4 color-6">Em breve um consultor entrará em contato.</span>
                                    </div>
                                    <div class="col-12 text-center py-4">
                                        <h2 class="mb-0 font-10" style="text-transform:uppercase; letter-spacing: 1px;">Caso tenha dúvidas?</h2>
                                        <a href="https://api.whatsapp.com/send?phone=556299957044" class="btn btn-info mt-1 font-15" style="background-color: var(--color);"><i class="fab fa-whatsapp font-24 align-middle mr-1"></i> FALAR COM NOSSA EQUIPE</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-12 col-lg-4 px-0 pt-0 pt-sm-4 pr-lg-0">

            </div>
        </div>
    </div>
</div>
</template>
<script>
export default {
    data() {
        return {
            
        };
    },
    computed:{
        user(){
            return this.$store.getters.getProfile;
        }
    },
    mounted() {
        (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:4932490,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
     })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    }
}
</script>